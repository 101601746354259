<template>
  <b10-base>
    <div
      v-for="dato in form.dato_articulo"
      :key="dato.idtdato_articulo"
    >
      <v-text-field
        v-model="dato.valor"
        :label="dato.tdato_articulo_nombre"
      />
    </div>
    <v-text-field
      v-model.number="form.unidades"
      type="number"
      label="Unidades"
      :rules="formRules.unidades"
    />
    <v-text-field
      v-model.number="form.nserie_inicial"
      type="number"
      label="Nº de serie inicial"
      :rules="formRules.nserie_inicial"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ServirLineaFormData'

export default {
  mixins: [formMixin],
  props: {
    idarticulo: {
      type: Number,
      required: true,
    },
    unidadesFaltaServir: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      form: {
        dato_articulo: [],
        nserie_inicial: null,
        unidades: null
      },
      formRules: {
        nserie_inicial: [
          v => !!v || 'Campo requerido'
        ],
        unidades: [
          v => !!v || 'Campo requerido'
        ]
      },
    }
  },
  async created () {
    this.$set(this.form, 'dato_articulo', await Data.selectDatoArticulo(this, this.idarticulo))
    this.$set(this.form, 'unidades', this.unidadesFaltaServir)
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
  },
}
</script>
